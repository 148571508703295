import React from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import { BgImage } from "gbimage-bridge"
import LayoutController from "../components/layoutController"

import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import NavigateBefore from "@material-ui/icons/NavigateBefore"
import NavigateNext from "@material-ui/icons/NavigateNext"

import Seo from "../components/seo"
import SocialShare from "../components/socialShare"
import Prose from "../components/prose"
import * as styles from "./articlePage.module.scss"

const ArticlePage = ({ data, pageContext }) => {
  const microCopyTexts = useMicroCopyFi
  // UI texts
  const labelArticlesFolder = GetMicroCopy(microCopyTexts, "polkuArtikkelit")
  const labelPremisesFolder = GetMicroCopy(microCopyTexts, "polkuKohteet")
  const labelSeePremiseInfo = GetMicroCopy(microCopyTexts, "kohdeKatsoTiedot")
  const labelToPremise = GetMicroCopy(microCopyTexts, "kohdeKohteeseen")
  const labelNext = GetMicroCopy(microCopyTexts, "yleinenSeuraavaksi")
  const labelEnter = GetMicroCopy(microCopyTexts, "yleinenAstuSisään")
  // end UI texts
  const previousPage = R.path(["prev"], pageContext)
  const nextPage = R.path(["next"], pageContext)
  const nextNode = R.path(["next_node"], pageContext)
  const nextSecondNode = R.path(["next_second_node"], pageContext)

  const nextSisustuskierrokset = []
  if (nextNode !== "") {
    nextSisustuskierrokset.push(nextNode)
  }
  if (nextSecondNode !== "") {
    nextSisustuskierrokset.push(nextSecondNode)
  }

  const pageContent = R.path(["contentfulContent"], data)
  const language = R.path(["node_locale"], pageContent)

  const title = R.path(["title"], pageContent)
  const category = R.path(["kategoria"], pageContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)
  const matterport = R.path(["matterportEsitys"], pageContent)

  const kohde = R.path(["kohdesivu"], pageContent)
  const kohdeHead = kohde && R.head(kohde)
  const kohdeSlug = R.path(["slug"], kohdeHead)
  const kohdeImage = R.path(["mainImage", "gatsbyImageData"], kohdeHead)

  /*
  No content to show atm
  const authorPicture = R.path(['kirjoittajanKuva', 'fluid'], pageContent);
  const authorTitle = R.path(['authorTitle'], pageContent);
  const author = R.path(['author'], pageContent);
  */

  // Youtube embedly help
  const regexEmbedlyText = new RegExp("Embedded content: (.*)", "g")
  const regexYouTube =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/g
  let textContent = R.path(["contentText", "contentText"], pageContent)
  if (textContent) {
    textContent = textContent
      .replace(regexEmbedlyText, "")
      .replace(regexYouTube, "https://youtu.be/$1?rel=0")
  }

  const contentTextShort = R.path(["contentTextShort"], pageContent)

  return (
    <LayoutController language={language}> 
    <Box>
      <Seo
        seoTitle={title}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <Box className={styles.titleContainer}>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
              <Grid item xs={10} className={styles.center}>
                <p className="categoryText">{category}</p>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={9}>
                    <h1 style={{ paddingRight: "20px" }}>{title}</h1>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={3}
                    style={{ display: "inline-flex", alignItems: "flex-end" }}
                  >
                    <SocialShare />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></Grid>
            </Grid>
          </Box>
          <Box className={styles.mainImageContainer}>
            <Grid container>
              <Grid
                item
                xs={1}
                className={styles.left}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {previousPage && (
                  <Link
                    style={{ color: "#000" }}
                    to={`/${labelArticlesFolder}/${previousPage}`}
                  >
                    <NavigateBefore style={{ fontSize: "48px" }} />
                  </Link>
                )}
              </Grid>
              <Grid item xs={10} className={styles.center}>
                {!matterport && (
                  <GatsbyImage
                    image={mainImage}
                    style={{ maxHeight: "656px" }}
                  />
                )}
                {matterport && (
                  <div className="iframecontainer">
                    <iframe
                      src={matterport}
                      title="matterport"
                      frameBorder="0"
                      allowFullScreen
                      allow="xr-spatial-tracking"
                    ></iframe>
                  </div>
                )}
              </Grid>
              <Grid
                item
                xs={1}
                className={styles.right}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {nextPage && (
                  <Link
                    style={{ color: "#000" }}
                    to={`/${labelArticlesFolder}/${nextPage}`}
                  >
                    <NavigateNext style={{ fontSize: "48px" }} />
                  </Link>
                )}
              </Grid>
            </Grid>
          </Box>
          <Grid container direction="row">
            <Grid item xs={12} lg={2}>
              {/*
              {authorPicture && <Img fluid={authorPicture} style={{width: '90px', height: '90px', borderRadius: '45px', marginBottom: '20px'}}/>}
              {(author && authorTitle)&& 
                <div>
                  <h3 style={{marginBottom: '0px'}}>{author}</h3>
                  <p>{authorTitle}</p>
                </div>
              }
            */}
            </Grid>
            <Grid item xs={12} lg={8}>
              <div style={{ marginBottom: "83px" }}>
                <Prose content={textContent} />
              </div>
            </Grid>
            <Grid item xs={12} lg={2}></Grid>
            <Grid item xs={12}>
              {kohdeHead && (
                <div
                  style={{
                    margin: "0 auto 30px auto",
                    maxWidth: "430px",
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    className={styles.premiseliftcontainer}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "flex-end",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        backgroundColor: "transparent",
                        width: "246px",
                        height: "265px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          clipPath:
                            "polygon(50% 0%, 100% 34%, 100% 100%, 0 100%, 0% 34%)",
                        }}
                      >
                        <BgImage
                          image={kohdeImage}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      className={styles.premiselifttextareacontainer}
                    >
                      <div
                        className={styles.premiselifttextarea}
                        style={{
                          backgroundColor: "#fcf380",
                          marginTop: "0px",
                          height: "176px",
                          paddingTop: "40px",
                        }}
                      >
                        <p style={{ marginBottom: "15px" }}>
                          {labelSeePremiseInfo}
                        </p>
                        <Link
                          to={`/${labelPremisesFolder}/${kohdeSlug}`}
                          className="pink"
                        >
                          <Button variant="contained" color="primary">
                            {labelToPremise}
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>

          {R.path(["slug"], R.head(nextSisustuskierrokset)) &&
            category === "Sisustuskierros" && (
              <Grid
                container
                spacing={3}
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <Grid item xs={12}>
                  <h2 className="premisesubtitle">{labelNext}</h2>
                </Grid>
                {nextSisustuskierrokset.map(item => {
                  let kat = R.path(["kategoria"], item)
                  const action = labelEnter
                  const folder = `${labelArticlesFolder}`

                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ width: "100%", marginBottom: "20px" }}
                      key={R.path(["slug"], item)}
                    >
                      <GatsbyImage
                        image={R.path(["mainImage", "gatsbyImageData"], item)}
                        style={{ maxHeight: "238px" }}
                      />
                      <p className="categoryText">{kat}</p>
                      <h3>
                        {R.path(["title"], item) || R.path(["name"], item)}
                      </h3>
                      <p>{R.path(["contentTextShort"], item)}</p>
                      <Link to={`/${folder}/${R.path(["slug"], item)}`}>
                        <Button variant="contained" color="primary">
                          {action}
                        </Button>
                      </Link>
                    </Grid>
                  )
                })}
              </Grid>
            )}
        </Box>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default ArticlePage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query articlePageQuery($slug: String) {
    contentfulContent: contentfulArtikkelisivu(slug: { eq: $slug }) {
      id
      title
      kategoria
      slug
      node_locale
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1200
          placeholder: BLURRED
        )
      }
      contentText {
        contentText
      }
      contentTextShort
      matterportEsitys
      kohdesivu {
        ... on Node {
          ... on ContentfulKohdesivu {
            __typename
            id
            slug
            mainImage {
              id
              file {
                url
              }
              gatsbyImageData(quality: 60, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
